import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* PAGES */
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Profile from "../pages/profile";
import StrategyPerformance from "../pages/strategies";

/* LAYOUT */
import Layout from "../layout/index";

/* STYLES */
import "../styles/global.css";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route
            path="/profile"
            element={
              <Layout>
                <Profile />
              </Layout>
            }
          />
          <Route
            path="/strategies/:algoId"
            element={
              <Layout>
                <StrategyPerformance />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
