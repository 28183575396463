/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import DataTable from "react-data-table-component";
import moment from "moment";

/* MATERIAL TAILWLIND COMPONENTS */
import { Card, CardBody, Button } from "@material-tailwind/react";

/* ICONS */
import { BsDownload } from "react-icons/bs";

/* CHARTS */
import {
  NetProfitLossGraphLabels,
  NetDailyBarGraph,
} from "../../components/chart";

/* IMAGES */
import Logo from "../../assets/images/logo.webp";

/* DATA */
import { useSelector } from "react-redux";
import { getUserTransaction, getUserTransactionGraph } from "../../service/api";
import { useParams } from "react-router-dom";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const Dashboard = () => {
  const { algoId } = useParams();

  const [loading, setLoading] = useState(false);
  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="pagination-loading-screen">
        <p>Please wait!</p>
        <p>We are fetching data </p>
      </div>
    );
  };

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalDocs, setTotalDocs] = useState(0);

  const { user } = useSelector((state) => state.user);
  const [datepicker, setDatepicker] = useState(false);

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
  };
  const handlePageChange = async (e) => {
    setPage(e);
  };

  const [startDate, setStartDate] = useState(
    moment(new Date().setMonth(new Date().getMonth() - 1)).format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );

  const [totalPL, setTotalPL] = useState(0);
  const [userTransactionList, setUserTransactionList] = useState([]);
  const [userTransactionGraphList, setUserTransactionGraphList] = useState([]);

  const [netProfitLossGraphLabelsData, setNetProfitLossGraphLabelsData] = useState([]);
  const [netDailyBarGraphData, setNetDailyBarGraphData] = useState([]);

  /* DOM NODE */
  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  /* GET USER TRANSACTION */
  const userTranscationData = () => {
    let params = {
      page: page,
      sizePerPage: sizePerPage,
      algo: algoId,
    };
    setLoading(true);
    getUserTransaction(params)
      .then((res) => {
        if (res?.status) {
          setUserTransactionList(res?.data);
          setTotalDocs(res?.data?.length);
          let count = 0;
          res?.data?.forEach((ele) => {
            if (ele.entryTime !== ele.exitTime) {
              if (ele.profit) {
                count = count + ele.profit;
              }
            }
          });
          setTotalPL(count);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      })
      .finally(() => {
        setLoading(false);
      });

    getUserTransactionGraph({ algo: algoId })
      .then((res) => {
        if (res?.status) {
          setUserTransactionGraphList(res?.data);
          let tempArrayCumulative = []
          let tempArrayDaily = []
          let cumulativePrice = 0
          res?.data?.forEach((element, index) => {
            tempArrayDaily.push([element?._id, element?.count])
            // tempArrayDaily.push([new Date(element?._id),tempArrayDaily[index - 1][0][1] + element?.count])

            cumulativePrice += element?.count
            tempArrayCumulative.push([element?._id, cumulativePrice])
          });
          setNetProfitLossGraphLabelsData(tempArrayCumulative)
          setNetDailyBarGraphData(tempArrayDaily)
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  /* DATE SELECTION RANGE */
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  /* USER COLUMNS */
  const columns = [
    {
      id: 1,
      width: "10vh",
      name: "Sr. No",
      selector: (row, index) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium justify-center">
          {index + 1}
        </span>
      ),
    },
    {
      id: 2,
      width: "30vh",
      name: "Ticker",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack font-nunito-bold">
          {row.tickerFullSymbol}
        </span>
      ),
    },
    {
      id: 3,
      name: "Entry Time",
      width: "22vh",
      selector: (row) => (
        <>
          <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
            {moment(row?.entryTime).format("DD-MM-YYYY HH:mm:ss A")}
          </span>
        </>
      ),
    },
    {
      id: 4,
      name: "Exit Time",
      width: "22vh",
      selector: (row) => (
        <>
          <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
            {row?.entryTime === row?.exitTime
              ? "-"
              : row?.exitTime === undefined
                ? "-"
                : moment(row?.exitTime).format("DD-MM-YYYY HH:mm:ss A")}
          </span>
        </>
      ),
    },
    {
      id: 5,
      name: "Quantity",
      width: "10vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row?.quantity}
        </span>
      ),
    },
    {
      id: 6,
      name: "Entry Price",
      width: "12vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          ₹{row?.price?.toFixed(2)}
        </span>
      ),
    },
    {
      id: 7,
      name: "Exit Price",
      width: "12vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row?.exitPrice === undefined ? "-" : `₹` + row?.exitPrice}
        </span>
      ),
    },
    {
      id: 10,

      name: "Algo",
      selector: (row) => (
        <span className={`text-sm tracking-tight   font-nunito-bold`}>
          Algo {row.algo}
        </span>
      ),
    },
    {
      id: 9,
      name: "PNL",
      selector: (row) => {
        const profit =
          (parseFloat(row?.exitPrice) - parseFloat(row?.price)) *
          parseFloat(row?.quantity);

        return (
          <span
            className={`text-sm tracking-tight text-center ${parseFloat(profit) < 0 ? "text-red-500" : "text-green-500"
              }  font-nunito-bold`}
          >
            {row.entryTime === row.exitTime
              ? "-"
              : isNaN(profit)
                ? "-"
                : (profit < 0 ? "- " : "+ ") + `₹` + Math.abs(profit).toFixed(2)}
          </span>
        );
      },
    },
  ];

  /* USER TRANSACTION DATA */
  useEffect(() => {
    userTranscationData();
  }, [page, sizePerPage, algoId]);

  return (
    <section className="py-6 xl:py-12 lg:py-12">
      <div className="container">
        {/* GREETING */}
        <div className="flex items-center justify-between">
          <div>
            <p className="text-base font-nunito-regular tracking-tight text-greyText">
              Strategy
            </p>
            <h1 className="text-3xl font-nunito-bold text-textBlack tracking-tight capitalize">
              {algoId === "4" && "Intraday Stock Buy"}
              {algoId === "3" && "Intraday Options Buy"}
              {algoId === "5" && "Positional Option Sell"}
              {algoId === "2" && "Intraday Option Sell"}
              {algoId === "1" && "PCR Strategy"}
            </h1>
            <p className="text-base font-nunito-regular tracking-tight text-greyText">
              Track the performance of your algorithm
            </p>
          </div>
        </div>

        {/* CHARTS AND STATS */}
        <div className="pt-5">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
            <div className="col-span-1">
              <Card className="bg-white border border-cardBorder shadow-md rounded-lg">
                <CardBody className="p-0">
                  <div className="border-b border-cardBorder dark:border-darkBorder p-5">
                    <h2 className="text-base font-nunito-semibold text-black tracking-tight">
                      Net daily P&L
                    </h2>
                    <p className="text-sm font-nunito-regular text-greyText tracking-tight">
                      Total net P&L for each trading day on the day it is
                      realized
                    </p>
                  </div>
                  <div className="p-0">
                    {netDailyBarGraphData?.length === 0 ? <p className="text-base py-5 px-5 font-nunito-regular text-greyText tracking-tight">
                      No Data found
                    </p> :
                      <NetDailyBarGraph data={netDailyBarGraphData} />}
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-span-1">
              <Card className="bg-white border border-cardBorder shadow-md rounded-lg">
                <CardBody className="p-0">
                  <div className="border-b border-cardBorder dark:border-darkBorder p-5">
                    <h2 className="text-base font-nunito-semibold text-black tracking-tight">
                      Cumulative P&L
                    </h2>
                    <p className="text-sm font-nunito-regular text-greyText tracking-tight">
                      Your total account P&L accumulated over the course of each
                      trading day
                    </p>
                  </div>
                  <div className="p-0">
                    {netProfitLossGraphLabelsData?.length === 0 ? <p className="text-base py-5 px-5 font-nunito-regular text-greyText tracking-tight">
                      No Data found
                    </p> :
                      <NetProfitLossGraphLabels data={netProfitLossGraphLabelsData} />}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>

        {/* REPORTS */}
        <div className="pt-5">
          <Card className="bg-white border border-cardBorder shadow-md rounded-lg">
            <CardBody className="p-0">
              <div className="p-5 border-b border-cardBorder">
                {/* DETAILS */}
                <div className="block xl:flex lg:flex items-center justify-between">
                  <div className="flex items-center gap-5">
                    <div className="w-12 h-12 rounded-full bg-header bg-opacity-5 flex items-center justify-center">
                      <img
                        src={Logo}
                        className="w-6 h-6 object-cover"
                        alt="Algosquant"
                      />
                    </div>
                    <h5 className="text-xl font-nunito-bold text-textBlack tracking-tight">
                      AlgosQuant
                    </h5>
                  </div>
                  <div className="block xl:flex lg:flex items-center gap-3 mt-5 xl:mt-0 lg:mt-0">
                    <div className="relative leading-none">
                      <div ref={domNode} className="form-group">
                        <input
                          type="text"
                          name="dateRange"
                          value={`${startDate} - ${endDate}`}
                          onChange={(e) => console.log(e)}
                          onClick={() => setDatepicker(!datepicker)}
                          className="w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Date range"
                        />
                      </div>
                      {datepicker && (
                        <div
                          ref={domNode}
                          className="absolute top-[5vh] bottom-0 transition-all duration-300 ease-linear z-50"
                        >
                          <DateRangePicker
                            ranges={[selectionRange]}
                            dragSelectionEnabled
                            showPreview
                            onChange={(e) => {
                              setStartDate(
                                moment(e.selection.startDate).format(
                                  "DD/MM/YYYY"
                                )
                              );
                              setEndDate(
                                moment(e.selection.endDate).format("DD/MM/YYYY")
                              );
                            }}
                            className="shadow-xl font-nunito-medium rounded-xl border border-cardBorder cursor-pointer"
                          />
                        </div>
                      )}
                    </div>
                    <Button className="w-full xl:w-auto lg:w-auto bg-header hover:bg-opacity-70 flex items-center justify-center mt-2 xl:mt-0 lg:mt-0 gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
                      <BsDownload className="w-4 h-4 text-white" />
                      Generate report
                    </Button>
                  </div>
                </div>

                {/* DEPLOYMENT DETAILS */}
                <div className="bg-[#F5F5F5] border border-[#E5E5E5] rounded-lg px-5 py-3 mt-5">
                  <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5 items-center">
                    <div className="col-span-1 xl:col-span-3 lg:col-span-3">
                      <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                        <div className="col-span-1 text-center xl:text-left lg:text-left">
                          <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                            Deployed on
                            <span className="font-nunito-bold block mt-2 text-lg">
                              -
                            </span>
                          </h5>
                        </div>
                        <div className="col-span-1 text-center xl:text-left lg:text-left">
                          <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                            Capital deployed
                            <span className="font-nunito-bold block mt-2 text-lg">
                              -
                            </span>
                          </h5>
                        </div>
                        <div className="col-span-1 text-center xl:text-left lg:text-left">
                          <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                            No. of contracts
                            <span className="font-nunito-bold block mt-2 text-lg">
                              -
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="w-full rounded-lg bg-white border border-cardBorder px-5 py-3">
                        <h5 className="text-sm font-nunito-medium text-black tracking-tight leading-none">
                          Total PNL:{" "}
                          <span
                            className={`text-lg font-nunito-bold ${totalPL.toFixed < 0
                              ? "text-red-500"
                              : "text-green-500"
                              } block mt-2`}
                          >
                            -
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-0">
                {/* TRADE LOGS */}
                <DataTable
                  columns={columns}
                  className="datatable rounded-md"
                  data={userTransactionList}
                  progressPending={loading}
                  progressComponent={<LoadingScreen />}
                  pagination
                  paginationServer
                  paginationTotalRows={totalDocs}
                  onChangeRowsPerPage={handlePerPageChange}
                  onChangePage={handlePageChange}
                  noDataComponent={
                    <div className="rounded-b-md py-10">
                      <p className="text-sm font-nunito-medium tracking-tight text-textBlack">
                        There are no records to display.
                      </p>
                    </div>
                  }
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
